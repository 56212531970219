import React, { useState, useEffect } from 'react';
import '../Footer/style.css';
import logoWhite from '../../assets/img/logo-purple.svg';
import phoneIcon from '../../assets/img/icons/phone.svg';
import facebookIcon from '../../assets/img/icons/facebook-icon.svg';
import tiktokIcon from '../../assets/img/icons/tiktokIcon.svg';
import instagramIcon from '../../assets/img/icons/instagram-icon.svg';
import EcoideasIcon from '../../assets/img/icons/icon-logo.png';
import gobiernocorp1 from '../../docs/CODIGO_DE_ETICA_Y_BUEN_GOBIERNO_CORPORATIVO.pdf';
import terminos1 from '../../docs/TERMINOS_Y_CONDICIONES_DE_USO_DEL_SITIO_ECOIDEAS.pdf';
import policie1 from '../../docs/POLITICAS_DE_PROTECCION_DE_DATOS_PERSONALES.pdf';
import PdfModal from '../Modals/PdfModal';

export const Footer = () => {
    const [showModal, setShowModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [modalTitle, setModalTitle] = useState('');
   //COMPLEMENTO DEL CODIGO identificar si el usuario esta en un dispositivo movil
    
    // const [isMobile, setIsMobile] = useState(false);

    // useEffect(() => {
    //     // Detectamos si el usuario está usando un dispositivo móvil
    //     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //     const mobileCheck = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
    //         userAgent.toLowerCase()
    //     );
    //     setIsMobile(mobileCheck);
    // }, []);

    const handleShowModal = (url, title) => {
        setPdfUrl(url);
        setModalTitle(title);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleButtonClick = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const os = /android/i.test(userAgent)
            ? 'android'
            : /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
            ? 'ios'
            : 'web';
        const ecoideasUrl =
            os === 'android'
                ? 'https://play.google.com/store/apps/details?id=com.ecoideas.app&hl=es'
                : os === 'ios'
                ? 'https://apps.apple.com/mx/app/ecoideas-app/id6475202942'
                : 'https://ecoideas.app';
        window.location.href = ecoideasUrl;
    };

    return (
        <footer className="container-footer">
            <div className="footer-section">
                <img src={logoWhite} alt="Ecoideas Logo" className="footer-logo" />
                <p className="footer-text">Somos la suma de aquellos que creen que el cambio es posible, de los apasionados que trabajan para hacer los sueños realidad.</p>
            </div>

            <div className="footer-section">
                <h6 className="footer-title">CONTÁCTANOS</h6>
                <ul className="footer-contact">
                    <li><img src={phoneIcon} alt="" className="footer-icon" /> (314) 810-1312</li>
                    <li><img src={phoneIcon} alt="" className="footer-icon" /> (314) 810-5161</li>
                    <li>servicioalcliente@biotu.com.co</li>
                </ul>
            </div>

            <div className="footer-section">
                <h6 className="footer-title">TÉRMINOS LEGALES</h6>
                <ul className="footer-links">
                    <li
                        style={{ textDecoration: 'underline' }}
                        onClick={() => handleShowModal(gobiernocorp1, 'Código De Ética Y Buen Gobierno Corporativo')}
                    >
                        Código De Ética Y Buen Gobierno Corporativo
                    </li>
                    <li
                        style={{ textDecoration: 'underline' }}
                        onClick={() => handleShowModal(policie1, 'Política De Protección De Datos Personales')}
                    >
                        Política De Protección De Datos Personales
                    </li>
                    <li
                        style={{ textDecoration: 'underline' }}
                        onClick={() => handleShowModal(terminos1, 'Términos Y Condiciones De Uso')}
                    >
                        Términos Y Condiciones De Uso
                    </li>
                </ul>
            </div>

            <div className="footer-section">
                <h6 className="footer-title">SÍGUENOS</h6>
                <div className="footer-social">
                    <a href="https://www.facebook.com/people/biotu/100064726338868/">
                        <img src={facebookIcon} alt="Facebook" className="footer-social-icon" />
                    </a>
                    <a href="https://www.tiktok.com/@bio.tu?_t=8jL9NIEVm1W&_r=1">
                        <img src={tiktokIcon} alt="TikTok" className="footer-social-icon" />
                    </a>
                    <a href="https://www.instagram.com/bio.tu/?igsh=YmY4M2g5N2Izd3E%3D">
                        <img src={instagramIcon} alt="Instagram" className="footer-social-icon" />
                    </a>
                    <a onClick={handleButtonClick}>
                        <img src={EcoideasIcon} alt="Ecoideas App" className="footer-social-icon" />
                    </a>
                </div>
            </div>

            {/* Modal solo en escritorio
            {!isMobile && showModal && <PdfModal pdfUrl={pdfUrl} modalTitle={modalTitle} />} */}
        {showModal && (
                <PdfModal
                    pdfUrl={pdfUrl}
                    modalTitle={modalTitle}
                    onClose={handleCloseModal}
                />
            )}
        
        </footer>
    );
};
