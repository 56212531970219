import React from 'react';
import { Translate } from '../../language';
import Button from '../../utils/Components/Button/Button';
import TermsAndConditions from './TermsAndConditions';
import { CloseIcon } from '../../assets/img/icons';
import './ModalTermsAndConditions.css';

const ModalTermsAndConditions = ({ displayModal, closeModal, isChecked }) => {

    const close = (e) => {
        e.stopPropagation();
        closeModal(e);
    };

    return (
        <>
            {/* Fondo difuminado */}
            <div className={`overlay ${displayModal ? 'active' : ''}`} />

            {/* Modal */}
            <div
                className={`modal-terms ${displayModal ? 'active' : ''}`}
                onClick={close}>
                <div
                    className={`modal-terms-content`}
                    onClick={e => e.stopPropagation()}>
                    <div
                        onClick={(e) => close(e)}
                        className="close-modal-terms">
                        <CloseIcon className="close-icon-terms" />
                    </div>
                    <div className="scroll-menu content-data-terms">
                        <TermsAndConditions />
                    </div>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            isChecked();
                            close(e);
                        }}
                        text={Translate('accept', 'es')}
                        classNameContent={'button-accept-termns'}
                    />
                </div>
            </div>
        </>
    );
};

export default ModalTermsAndConditions;
