import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const PdfModal = ({ pdfUrl, modalTitle, onClose }) => {
  const [showModal, setShowModal] = useState(false);
 /**
 * Componente PdfModal
 * Este componente muestra un modal con un visualizador de PDF utilizando Bootstrap y React.
 * 
 * Props:
 * - pdfUrl (string): URL del PDF que se mostrará en el modal.
 * - modalTitle (string): Título opcional para el modal. Por defecto, "Visualizador de PDF".
 * - onClose (function): Callback opcional que se ejecuta al cerrar el modal.
 * 
 * Características:
 * - Muestra el modal automáticamente si se proporciona un pdfUrl válido.
 * - Permite cerrar el modal mediante un botón de cierre.
 * - Usa un iframe para cargar y mostrar el PDF directamente.
 * - Compatible con dispositivos responsivos y accesible con atributos ARIA.
 */
  // const [isMobile, setIsMobile] = useState(false);

  //useEffect(() => {
    // Detectamos si el dispositivo es móvil
   // const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    //const mobileCheck = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
     // userAgent.toLowerCase()
    //);
   // setIsMobile(mobileCheck);
 // }, []);

 // const handleClose = () => setShowModal(false);

  //useEffect(() => {
    // Si el dispositivo es móvil, abrimos el PDF en una nueva pestaña
   // if (isMobile && pdfUrl) {
   //   window.open(pdfUrl, "_blank");
   // } else if (pdfUrl) {
    //  setShowModal(true);
   // }
  //}, [pdfUrl, isMobile]);
  useEffect(() => {
    if (pdfUrl) {
      setShowModal(true);
    }
  }, [pdfUrl]);

  const handleClose = () => {
    setShowModal(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  // if (isMobile) {
  //   return null; // No renderizamos el modal en dispositivos móviles
  // }

  return (
    <>
  
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          role="dialog"
        >
          <div className="modal-dialog modal-xl" role="document">
            <div className="modal-content">
              <div className="modal-header">
              <h5 className="modal-title text-center w-100">{modalTitle || "Visualizador de PDF"}</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={handleClose}
                  
                ></button>
              </div>
              <div className="modal-body" style={{ height: "80vh" }}>
                {/* Usamos un iframe para cargar y mostrar el PDF */}
                <iframe
                  src={pdfUrl}
                  title="PDF Viewer"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                ></iframe>
              </div>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PdfModal;
