//PaymentSummary.js
import React, { useEffect, useState } from 'react'
import { Translate } from '../../language';

import Button from '../../utils/Components/Button/Button';
import Input from '../../utils/Components/Input/Input';
import { formatPrice } from '../../utils/functions';
import { Modal } from 'react-bootstrap';
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { createPaymentlink } from '../../redux/actions/PasarelaActions'
import useMercadoPago from '../../utils/hooks/useMercadoPago'
import inMemoryJwt from '../../auth/inMemoryJwt';


import ColombiaLocationSelector from '../../utils/Components/Country/ColombiaLocationSelector';


import "./ShopingBag.css"
import { current } from '@reduxjs/toolkit';

const PaymentSummary = ({
    isLoadingPointsToRedeem,
    type,
    deliveryEntityId,
    setDeliveryEntityId,
    pointsRedem,
    setPointsRedem,
    pointsToRedeem,
    listOfReportsLimit,
    subTotal,
    total,
    pay,
    typeUser,
    disscount,
    isPoints,
    totalPoints,
    typeOfDelivery,
    setTypeOfDelivery,
    wareHausesWinery = [],
    typeOfPay,
    setTypeOfPay,
}) => {

    const [isEnoughEcopoints, setIsEnoughEcopoints] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [country, setCountry] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [nationalId, setNationalId] = useState('');
    const [phone, setPhone ] = useState('');
    const [email, setEmail] = useState('');
    const [state, setState] = useState('');
    const mp = useMercadoPago();
    const [city, setCity] = useState('');
    const [location, setLocation] = useState('');
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const dispatch = useDispatch()
    //const history = useNavigate()
    const { userInfo, bag, ubication } = useSelector((state) => state.marketPlace)
    const token = inMemoryJwt.getToken();  //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXlsb2FkIjp7ImlkIjoiNjdhN2M4Yzc1ZTRiMzE3MjQ1MTMxMWNkIiwiZW1haWwiOiJlY29pZGVhcy5jb21wcmFzQGdtYWlsLmNvbSIsImN1cnJlbnRSb2xlIjoidXNlciIsIlJPTF9VU0VSIjoidXNlciJ9LCJpYXQiOjE3MzkwNDkxNTksImV4cCI6MTczOTY1Mzk1OX0.iasz11ZjeDJSSNRnUrDymFRdTL7hsR2NLTLWRaLI';
    const userId = userInfo?._id || '67a7c8c75e4b3172451311cd';

    const [shouldRunEffect, setShouldRunEffect] = useState(false);
    const handleFinalizePurchase = () => {
        setShouldRunEffect(true);
    };
    useEffect(() => {
        if (shouldRunEffect) {
            console.log('Token:', token);
            console.log('Bag:', bag);
            console.log('User ID:', userInfo?._id);
            console.log('User ID:', userId);
            console.log('Country:', country);
            console.log('State:', state);
            console.log('City:', city);
            console.log('Location:', location);
            console.log('Address:', deliveryAddress);
            console.log('Phone:', phone);
            console.log('Email:', email);
            console.log('Name:', name);
            console.log('Last Name:', lastName);
            console.log('National ID:', nationalId);
            console.log('MP:', mp);
            //console.log('History:', history);
    
            dispatch(
                createPaymentlink(
                    token,
                    bag,
                    userId,
                    'user',
                    'home',
                    {
                        currentCountry: country,
                        currentDepartment: state,
                        currentCity: city,
                        currentLocation: location,
                        currentAddress: deliveryAddress,
                        currentPhone: phone,
                        currentEmail: email,
                        currentName: name,
                        currentLastName: lastName,
                        currentNationalId: nationalId,
                    },
                    phone,
                    null,
                    'pesos',
                    0,
                    mp
                    //history
                )
            );
    
            setShouldRunEffect(false);
        }
    }, [
        shouldRunEffect,
        token,
        userId,
        bag,
        userInfo,
        country,
        state,
        city,
        location,
        deliveryAddress,
        phone,
        email,
        name,
        lastName,
        nationalId,
        mp,
        //history,
        dispatch,
    ]);
    const payment_lock = () => {
        if (subTotal < 150000) {
            setShowModal(true);
        } else {
            pay();
        }
    };

    const handleClose = () => setShowModal(false);


    return (
        <div className="payment-summary" >
            <div className="payment-summary-tittle">
                <div className=" text-center text-bold color-purple-dark">Resumen de tu orden</div>
            </div>
            <hr />
            <div className="text-center">
                <div className='container-width margin-center'>
                    <div className='color-purple-dark'>Contacto</div>

                    {
                        typeOfDelivery == "home" && (

                            <div className='mt-2'>
                            <Input
                                center={false}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder='Correo electrónico'
                            />
                            <Input
                                center={false}
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder='Nombre'
                            />
                            <Input
                                center={false}
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                placeholder='Apellido'
                            />
                            <Input
                                center={false}
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                placeholder='Teléfono'                            
                            />
                            <Input
                                center={false}
                                value={nationalId}
                                onChange={e => setNationalId(e.target.value)}
                                placeholder='Cédula'
                            />
                        <br/>
                        <div className='color-purple-dark'>Datos de envio</div>
                                <select
                                    value={country}
                                    onChange={e => setCountry(e.target.value)}
                                    style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '10px' }}
                                >
                                <option value="" disabled hidden>País</option>
                                <option value="Colombia">Colombia</option> 
                                </select>

                                {country === 'Colombia' && (
                                    <ColombiaLocationSelector
                                        state={state}
                                        setState={setState}
                                        city={city}
                                        setCity={setCity}
                                    />
                                )}
                                
                                <Input
                                    center={false}
                                    value={deliveryAddress}
                                    onChange={e => setDeliveryAddress(e.target.value)}
                                    placeholder='Dirección de envío'
                                />
                                <Input
                                    center={false}
                                    value={location}
                                    onChange={e => setLocation(e.target.value)}
                                    placeholder='Barrio o informacion adicional'
                                />

                            </div>
                        )

                    }

                </div>


                {
                    <div>
                        <span className='text-color-green'>Valor total al pagar: </span> {formatPrice(subTotal)}
                    </div>
                }


                <div>
                    <Button
                        disabled={
                            deliveryAddress.lenght <= 5
                        }
                        onClick={handleFinalizePurchase}
                        text='Finalizar compra'
                    />
                </div>

                <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Aviso</Modal.Title>
                </Modal.Header>
                <Modal.Body>Para aprobar tu compra el pedido debe ser mayor a $ 150.000 COP</Modal.Body>
            </Modal>
            </div>
        </div>
    )

}
export default PaymentSummary
